import React, {Component} from 'react';
import {findDOMNode} from 'react-dom'
import { Field, change } from 'redux-form';
import {FormTemplateField} from '../../../../others'
import {Input, Textarea, InputWithIcon, CheckboxGroup, InputDropdown} from '../../../../form/'
import { rangeBuilder } from '../../../../HelperFunctions'
import moment from 'moment'
import SetDistanceNear from '../SetDistanceNear';
import { connect } from 'react-redux';
import { signedNumber, twoDecimalPlaces } from '../../../../form/FormValidations';


class GlassForm extends Component{

  setGlassDistanceNear(visual, value) {
    const { name } = this.props
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[visual_acuity][od_${visual}]`, value))
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[visual_acuity][os_${visual}]`, value))
    this.props.dispatch(change('examRefractionContactLensForm', `${name}[visual_acuity][ou_${visual}]`, value))
  }

  copyTo(source, destination){
    const {name, pprops} = this.props
    const sphereNode = findDOMNode(this.refs[`${name}[${source}_sphere]`]).lastChild.value
    const cylinderNode = findDOMNode(this.refs[`${name}[${source}_cylinder]`]).firstChild.value
    const axisNode = findDOMNode(this.refs[`${name}[${source}_axis]`]).firstChild.value
    const addNode = findDOMNode(this.refs[`${name}[${source}_add]`]).firstChild.value
    const prismNode = findDOMNode(this.refs[`${name}[${source}_prism]`]).firstChild.value
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_sphere]`, sphereNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_cylinder]`, cylinderNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_axis]`, axisNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_add]`, addNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_prism]`, prismNode))
  }

  getDropUsedOptions(){
    const {fieldValues} = this.props
    var options = [];
    ['drop1', 'drop2', 'drop3', 'drop4'].map(drop => {
      if(fieldValues['fields'][drop] && fieldValues['fields'][drop].visible && fieldValues['fields'][drop]['default_value'] !== "")
        options.push({label: fieldValues['fields'][drop]['default_value'], value: fieldValues['fields'][drop]['default_value']})
    })
    return options
  }

  updateCorrespondingAxisValue = (e, name, glassType) => {
    const { dispatch } = this.props
    if (["DS", " "].includes(e?.target?.value)) {
      dispatch(change('examRefractionContactLensForm', `${name}[${glassType}_axis]`, null))
    }
  }

  handleOnChange(val, destination) {
    const { name, pprops } = this.props
    // assign OD val to OS
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}]`, val))
  }

  render(){
    const {name, baseName, fieldValues, isTemplate, pprops, isCurrentGlass} = this.props
    
    return (
      <span>
        <div className="row">
          {isCurrentGlass &&
            <FormTemplateField
              isTemplate={isTemplate}
              name={`${name}[glass_type]`}
              baseName={`${baseName}[fields][glass_type]`}
              fieldValues={fieldValues['fields']['glass_type']}
              component={Input} 
              className="form-control"
              wrapperClasses="col-5"
            />
          }
        </div>
        <div className="row mt-5">
          <div className="col-8">
            <div className="row">
              <Field
                ref={`${name}[od_sphere]`}
                name={`${name}[od_sphere]`}
                nextFocusRef={this.refs[`${name}[od_cylinder]`]}
                label={this.renderOdLabel()}
                component={InputDropdown}
                data={[...rangeBuilder(25, -25.25, -0.25, '+', 'pl')]}
                additionalValues={{ last: [{ key: '' }, { key: 'Bal' }] }}
                textField="key"
                valueField="key"
                className='px-1'
                wrapperClasses="col pr-0 label-icons"
                validate={[signedNumber, twoDecimalPlaces]} />
              <Field
                ref={`${name}[od_cylinder]`}
                name={`${name}[od_cylinder]`}
                nextFocusRef={this.refs[`${name}[od_axis]`]}
                component={InputDropdown}
                additionalValues={{ last: [{ key: '' }] }}
                data={[...rangeBuilder(10, -10.25, -0.25, '+', 'DS', 2)]}
                textField="key" valueField="key" wrapperClasses="col pr-0 pt-6" className='px-1' onChange={(e) => this.updateCorrespondingAxisValue(e, name, 'od')}
                validate={[signedNumber, twoDecimalPlaces]}
              />
              <Field
                ref={`${name}[od_axis]`}
                name={`${name}[od_axis]`}
                nextFocusRef={this.refs[`${name}[od_add]`]}
                component={InputDropdown}
                data={rangeBuilder(1, 181, 1, '', '', 0)}
                textField="key"
                valueField="key"
                wrapperClasses="col pr-0 pt-6"
                className='px-1'
              />
              <Field
                ref={`${name}[od_add]`}
                name={`${name}[od_add]`}
                nextFocusRef={this.refs[`${name}[os_sphere]`]}
                onChangeHook={(val) => this.handleOnChange(val, 'os_add')}
                component={InputDropdown}
                data={[{ key: ' ' }, { key: 'ADD' }, ...rangeBuilder(0.25, 6.25, 0.25, '+', '0.00')]}
                textField="key"
                valueField="key"
                wrapperClasses="col pr-0 pt-6"
                className='px-1 form-control'
                validate={[signedNumber, twoDecimalPlaces]}
              />
              <Field ref={`${name}[od_prism]`} name={`${name}[od_prism]`} component={Input} placeholder="Prism" className="form-control mt-6" wrapperClasses="col form-group" />
            </div>
            <div className="row">
              <Field
                ref={`${name}[os_sphere]`}
                name={`${name}[os_sphere]`}
                nextFocusRef={this.refs[`${name}[os_cylinder]`]}
                additionalValues={{ last: [{ key: '' }, { key: 'Bal' }] }}
                label={this.renderOsLabel()}
                component={InputDropdown}
                data={[...rangeBuilder(25, -25.25, -0.25, '+', 'pl')]}
                textField="key"
                valueField="key"
                className='px-1'
                wrapperClasses="col pr-0 label-icons"
                validate={[signedNumber, twoDecimalPlaces]} />
              <Field
                ref={`${name}[os_cylinder]`}
                name={`${name}[os_cylinder]`}
                nextFocusRef={this.refs[`${name}[os_axis]`]}
                additionalValues={{ last: [{ key: '' }] }}
                component={InputDropdown}
                data={[...rangeBuilder(10, -10.25, -0.25, '+', 'DS', 2)]}
                textField="key"
                valueField="key"
                wrapperClasses="col pr-0 pt-6"
                className='px-1'
                onChange={(e) => this.updateCorrespondingAxisValue(e, name, 'os')}
                validate={[signedNumber, twoDecimalPlaces]}
              />
              <Field
                ref={`${name}[os_axis]`}
                name={`${name}[os_axis]`}
                nextFocusRef={this.refs[`${name}[os_add]`]}
                component={InputDropdown}
                data={rangeBuilder(1, 181, 1, '', '', 0)}
                textField="key"
                valueField="key"
                wrapperClasses="col pr-0 pt-6"
                className='px-1'
              />
              <Field
                ref={`${name}[os_add]`}
                name={`${name}[os_add]`}
                component={InputDropdown}
                onChangeHook={(val) => this.handleOnChange(val, 'od_add')}
                data={[{ key: ' ' }, { key: 'ADD' }, ...rangeBuilder(0.25, 6.25, 0.25, '+', '0.00')]}
                textField="key"
                valueField="key"
                wrapperClasses="col pr-0 pt-6"
                className='px-1'
                validate={[signedNumber, twoDecimalPlaces]}
              />
              <Field
                ref={`${name}[os_prism]`}
                name={`${name}[os_prism]`}
                component={Input} placeholder="Prism" className="form-control mt-6" wrapperClasses="col form-group" />
            </div>
            <div className="row">
              <FormTemplateField
                isTemplate={isTemplate}
                name={`${name}[notes]`}
                baseName={`${baseName}[fields][notes]`}
                fieldValues={fieldValues['fields']['notes']}
                component={Textarea} 
                rows={ name==="cycloplegic_attributes" ? 1 : 4}
                className="form-control"
                wrapperClasses="col-8 form-group label-icons"
              />

              { name==="cycloplegic_attributes" &&
                <div className="col-4">
                  <Field name="cycloplegic_attributes[instilled_at]" label="Instilled at" component={Input} className="form-control" wrapperClasses="col-12 pr-5 pl-0 form-group" />
                </div>
              }

            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <Field ref={`${name}[visual_acuity][od_distance]`} name={`${name}[visual_acuity][od_distance]`} label="OD Distance" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-6 form-group px-3" labelClasses="w-100"/>
              <Field ref={`${name}[visual_acuity][od_near]`} name={`${name}[visual_acuity][od_near]`} label="OD Near" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-6 form-group px-3" labelClasses="w-100"/>
              <Field ref={`${name}[visual_acuity][os_distance]`} name={`${name}[visual_acuity][os_distance]`} label="OS Distance" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-6 form-group px-3" labelClasses="w-100"/>
              <Field ref={`${name}[visual_acuity][os_near]`} name={`${name}[visual_acuity][os_near]`} label="OS Near" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-6 form-group px-3" labelClasses="w-100"/>
              <Field ref={`${name}[visual_acuity][ou_distance]`} name={`${name}[visual_acuity][ou_distance]`} label="OU Distance" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-6 form-group px-3" labelClasses="w-100"/>
              <Field ref={`${name}[visual_acuity][ou_near]`} name={`${name}[visual_acuity][ou_near]`} label="OU Near" component={InputWithIcon} title="20/" className="form-control" wrapperClasses="col-6 form-group px-3" labelClasses="w-100"/>
              {
                !isTemplate &&
                <>
                  <SetDistanceNear setVisualAcuity={this.setGlassDistanceNear.bind(this)} visual='distance' />
                  <SetDistanceNear setVisualAcuity={this.setGlassDistanceNear.bind(this)} visual='near' isDropLeft={true} />
                </>
              }
            </div>
          </div>

          { name==="cycloplegic_attributes" &&
            <div className="col-12">
              { !isTemplate ?
                <div className="row">
                  <CheckboxGroup 
                    onClick={(value)=> {!value && !isTemplate && pprops.dispatch(change('examRefractionContactLensForm', 'cycloplegic_attributes[instilled_at]', moment().format("hh:mm A")))}} 
                    label="Drop(s) Used"
                    name="cycloplegic_attributes[drops_used]"
                    options={this.getDropUsedOptions()}
                    // options={[{label:"1st drop", value: "1stDrop"}, {label:"Cyclo", value: "cyclo"}, {label:"Number 4", value: "number_4"}]} 
                    className="my-2 mx-4"
                    wrapperClasses="col-12"
                  />
                  {/* <Field name="cycloplegic_attributes[instilled_at]" label="Instilled at" component={Input} className="form-control" wrapperClasses="col-5 pr-5 pl-0 form-group" /> */}
                </div>
                :
                <div className="row">
                  <h6 className="col-12 py-5">Drop(s) Used</h6>
                  <FormTemplateField
                    isTemplate={isTemplate}
                    name={`${name}[drop1]`}
                    baseName={`${baseName}[fields][drop1]`}
                    fieldValues={fieldValues['fields']['drop1']}
                    component={Input}
                    className="form-control"
                    wrapperClasses="col-3 form-group"
                  />
                  <FormTemplateField
                    isTemplate={isTemplate}
                    name={`${name}[drop2]`}
                    baseName={`${baseName}[fields][drop2]`}
                    fieldValues={fieldValues['fields']['drop2']}
                    component={Input} 
                    className="form-control"
                    wrapperClasses="col-3 form-group"
                  />
                  <FormTemplateField
                    isTemplate={isTemplate}
                    name={`${name}[drop3]`}
                    baseName={`${baseName}[fields][drop3]`}
                    fieldValues={fieldValues['fields']['drop3']}
                    component={Input} 
                    className="form-control"
                    wrapperClasses="col-3 form-group"
                  />
                  <FormTemplateField
                    isTemplate={isTemplate}
                    name={`${name}[drop4]`}
                    baseName={`${baseName}[fields][drop4]`}
                    fieldValues={fieldValues['fields']['drop4']}
                    component={Input} 
                    className="form-control"
                    wrapperClasses="col-3 form-group"
                  />
                </div>
              }
            </div>
          }
        </div>
      </span>
    )
  }

  
  renderOdLabel(){
    return(
      <span>OD<small className="float-right btn-link" onClick={()=> !this.props.isTemplate && this.copyTo('od', 'os')}><i className="la la-copy"/>OS</small></span>
      )
    }

  renderOsLabel(){
    return(
      <span>OS<small className="float-right btn-link" onClick={()=> !this.props.isTemplate && this.copyTo('os', 'od')}><i className="la la-copy"/>OD</small></span>
    )
  }
}

export default connect()(GlassForm)
