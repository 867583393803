import React, { Component } from 'react';
import { findDOMNode } from 'react-dom'
import { Field, change } from 'redux-form';
import { FormTemplateField } from '../../../../others'
import { Input, Textarea, Select, RadioGroup, FilesUpload, InputDropdown } from '../../../../form/'
import { KERATOMETRY_DIOPTER, KERATOMETRY_MM, KERATOMETRY_AXIS } from '../../../../Constants'
class Keratometry extends Component {

  copyTo(source, destination) {
    const { name, pprops } = this.props
    const diopterHorizontalNode = findDOMNode(this.refs[`${name}[${source}_horizontal_diopter]`]).lastChild.value
    const mmHorizontalNode = findDOMNode(this.refs[`${name}[${source}_horizontal_mm]`]).lastChild.value
    const axisHorizontalNode = findDOMNode(this.refs[`${name}[${source}_horizontal_axis]`]).lastChild.value
    const diopterVerticalNode = findDOMNode(this.refs[`${name}[${source}_vertical_diopter]`]).lastChild.value
    const mmVerticalNode = findDOMNode(this.refs[`${name}[${source}_vertical_mm]`]).lastChild.value
    const axisVerticalNode = findDOMNode(this.refs[`${name}[${source}_vertical_axis]`]).lastChild.value
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_horizontal_diopter]`, diopterHorizontalNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_horizontal_mm]`, mmHorizontalNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_horizontal_axis]`, axisHorizontalNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_vertical_diopter]`, diopterVerticalNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_vertical_mm]`, mmVerticalNode))
    pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}_vertical_axis]`, axisVerticalNode))
  }

  UpdateCorrespondingValue(destination, SelectedValue, SourceDropdown, DestinationDropdown) {
    const { name, pprops, isTemplate } = this.props
    const CheckValue = typeof(SelectedValue) === 'object' ? SelectedValue.target.value : SelectedValue
    const index = SourceDropdown.findIndex((element => element.value === CheckValue))
    !isTemplate && pprops.dispatch(change('examRefractionContactLensForm', `${name}[${destination}]`, DestinationDropdown[index].value))
  }
  render() {
    const { isTemplate, template, name, pprops } = this.props
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-12">
            <div className="row">
              <div className="col-4">
                <Field component={Input} placeholder="Horizontal" label={this.renderOdLabel()}
                  className="form-control border-0 p-0 disabled" wrapperClasses="form-group label-icons" />
              </div>
              <Field
                ref={`${name}[od_horizontal_diopter]`}
                name={`${name}[od_horizontal_diopter]`}
                nextFocusRef={this.refs[`${name}[od_horizontal_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('od_horizontal_mm', e, KERATOMETRY_DIOPTER, KERATOMETRY_MM) }}
                label="Diopter" component={InputDropdown} data={KERATOMETRY_DIOPTER} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-9rem'} />
              <Field
                ref={`${name}[od_horizontal_mm]`}
                name={`${name}[od_horizontal_mm]`}
                nextFocusRef={this.refs[`${name}[od_horizontal_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('od_horizontal_diopter', e, KERATOMETRY_MM, KERATOMETRY_DIOPTER) }}
                label="MM" component={InputDropdown} data={KERATOMETRY_MM} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-17rem'}  />
              <Field
                ref={`${name}[od_horizontal_axis]`}
                name={`${name}[od_horizontal_axis]`}
                nextFocusRef={this.refs[`${name}[os_horizontal_diopter]`]}
                label="Axis" component={InputDropdown} data={KERATOMETRY_AXIS} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-27rem'} />
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 col-md-12">
            <div className="row">
              <div className="col-4">
                <Field component={Input} label={this.renderOdLabel()} placeholder="Vertical" className="form-control border-0 p-0 disabled" wrapperClasses="form-group label-icons" />
              </div>
              <Field
                ref={`${name}[od_vertical_diopter]`}
                name={`${name}[od_vertical_diopter]`}
                nextFocusRef={this.refs[`${name}[od_vertical_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('od_vertical_mm', e, KERATOMETRY_DIOPTER, KERATOMETRY_MM) }}
                label="Diopter" component={InputDropdown} data={KERATOMETRY_DIOPTER} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-51rem'}  />
              <Field
                ref={`${name}[od_vertical_mm]`}
                name={`${name}[od_vertical_mm]`}
                nextFocusRef={this.refs[`${name}[od_vertical_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('od_vertical_diopter', e, KERATOMETRY_MM, KERATOMETRY_DIOPTER) }}
                label="MM" component={InputDropdown} data={KERATOMETRY_MM} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-59rem'} />
              <Field
                ref={`${name}[od_vertical_axis]`}
                name={`${name}[od_vertical_axis]`}
                nextFocusRef={this.refs[`${name}[os_vertical_diopter]`]}
                label="Axis" component={InputDropdown} data={KERATOMETRY_AXIS} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-70rem'} />
            </div>
          </div>

          {/* <Field name="keratometry_attributes[od_notes]" label="OD Notes" rows={1} component={Textarea} className="form-control" wrapperClasses="col-xl-2 col-lg-6 col-md-12"/> */}
          <FormTemplateField
            isTemplate={isTemplate}
            name="keratometry_attributes[od_notes]"
            baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][od_notes]"
            fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.od_notes}
            component={Textarea}
            rows={1}
            className="form-control"
            wrapperClasses="col-xl-2 col-lg-6 col-md-12"
          />
        </div>
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="row">
              <div className="col-4">
                <Field component={Input} placeholder="Horizontal"
                  label={this.renderOsLabel()} className="form-control border-0 p-0 disabled" wrapperClasses="form-group  label-icons" />
              </div>
              <Field
                ref={`${name}[os_horizontal_diopter]`}
                name={`${name}[os_horizontal_diopter]`}
                nextFocusRef={this.refs[`${name}[os_horizontal_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('os_horizontal_mm', e, KERATOMETRY_DIOPTER, KERATOMETRY_MM) }}
                label="Diopter" component={InputDropdown} data={KERATOMETRY_DIOPTER} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-9rem'} />

              <Field
                ref={`${name}[os_horizontal_mm]`}
                name={`${name}[os_horizontal_mm]`}
                nextFocusRef={this.refs[`${name}[os_horizontal_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('os_horizontal_diopter', e, KERATOMETRY_MM, KERATOMETRY_DIOPTER) }}
                label="MM" component={InputDropdown} data={KERATOMETRY_MM} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-17rem'} />
              <Field
                ref={`${name}[os_horizontal_axis]`}
                name={`${name}[os_horizontal_axis]`}
                nextFocusRef={this.refs[`${name}[od_vertical_diopter]`]}
                label="Axis" component={InputDropdown} data={KERATOMETRY_AXIS} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-27rem'} />
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 col-md-10">
            <div className="row">
              <div className="col-4">
                <Field component={Input} label={this.renderOsLabel()} placeholder="Vertical" className="form-control border-0 p-0 disabled" wrapperClasses="form-group label-icons" />
              </div>
              <Field
                ref={`${name}[os_vertical_diopter]`}
                name={`${name}[os_vertical_diopter]`}
                nextFocusRef={this.refs[`${name}[os_vertical_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('os_vertical_mm', e, KERATOMETRY_DIOPTER, KERATOMETRY_MM) }}
                label="Diopter" component={InputDropdown} data={KERATOMETRY_DIOPTER} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-51rem'} />
              <Field
                ref={`${name}[os_vertical_mm]`}
                name={`${name}[os_vertical_mm]`}
                nextFocusRef={this.refs[`${name}[os_vertical_axis]`]}
                onChange={e => { this.UpdateCorrespondingValue('os_vertical_diopter', e, KERATOMETRY_MM, KERATOMETRY_DIOPTER) }}
                label="MM" component={InputDropdown} data={KERATOMETRY_MM} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-59rem'} />

              <Field
                ref={`${name}[os_vertical_axis]`}
                name={`${name}[os_vertical_axis]`}
                label="Axis" component={InputDropdown} data={KERATOMETRY_AXIS} textField="value" valueField="value" wrapperClasses="col-xl-2 col-lg col-md-2 px-1" dropdownClasses='keratometry-input-dropdown' leftPosition={'-70rem'}/>
            </div>
          </div>
          {/* <Field name="keratometry_attributes[os_notes]" label="OS Notes"  component={Textarea} className="form-control" wrapperClasses="col-xl-2 col-lg-6 col-md-12"/> */}
          <FormTemplateField
            isTemplate={isTemplate}
            name="keratometry_attributes[os_notes]"
            baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][os_notes]"
            fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.os_notes}
            component={Textarea}
            rows={1}
            className="form-control"
            wrapperClasses="col-xl-2 col-lg-6 col-md-12"
          />
        </div>

        <p className="mt-5 mb-3">Topography</p>
        <div className="row">
          <FormTemplateField
            isTemplate={isTemplate}
            name={`keratometry_attributes[od_topography_images_attributes]`}
            baseName={`refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][images1]`}
            fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.images1}
            component={FilesUpload}
            wrapperClasses="col-2"
            imageClasses="py-3 px-5 uploaded-file-width"
          />
          <div className="col-2">
            <div className="row">
              <FormTemplateField
                isTemplate={isTemplate}
                name="keratometry_attributes[topography_fields][order_od]"
                baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][order_od]"
                fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.order_od}
                component={Input}
                className="form-control"
                wrapperClasses="col-12 form-group label-icons"
              />
              <FormTemplateField
                isTemplate={isTemplate}
                name="keratometry_attributes[topography_fields][interpretation_od]"
                baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][interpretation_od]"
                fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.interpretation_od}
                component={Textarea}
                className="form-control"
                wrapperClasses="col-12 form-group label-icons"
              />
            </div>
          </div>
          <FormTemplateField
            isTemplate={isTemplate}
            name={`keratometry_attributes[os_topography_images_attributes]`}
            baseName={`refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][images2]`}
            fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.images2}
            component={FilesUpload}
            wrapperClasses="offset-1 col-2 "
            imageClasses="py-3 px-5 uploaded-file-width"
          />
          <div className="col-2">
            <div className="row">
              <FormTemplateField
                isTemplate={isTemplate}
                name="keratometry_attributes[topography_fields][order_os]"
                baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][order_os]"
                fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.order_os}
                component={Input}
                className="form-control"
                wrapperClasses="col-12 form-group label-icons"
              />
              <FormTemplateField
                isTemplate={isTemplate}
                name="keratometry_attributes[topography_fields][interpretation_os]"
                baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry][fields][interpretation_os]"
                fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry.fields.interpretation_os}
                component={Textarea}
                className="form-control"
                wrapperClasses="col-12 form-group label-icons"
              />
            </div>
          </div>
          <div className="col-3">
            <RadioGroup label="Dominant Eye" name="keratometry_attributes[dominant_eye]" options={[{ label: 'OD', value: 'OD' }, { label: 'OS', value: 'OS' }, { label: 'None', value: 'None' }]} />
          </div>
        </div>
      </div>
    )
  }

  renderOdLabel() {
    return (
      <span>OD<small className="float-right btn-link w-75"
        onClick={() => !this.props.isTemplate && this.copyTo('od', 'os')}><i className="la la-copy" />OS</small></span>
    )
  }

  renderOsLabel() {
    return (
      <span>OS<small className="float-right btn-link w-75" onClick={() => !this.props.isTemplate && this.copyTo('os', 'od')}><i className="la la-copy" />OD</small></span>
    )
  }
}

export default Keratometry;